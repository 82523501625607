const utils={
    goShowMoney(url) {
        // 回调
        sessionStorage.removeItem("label");
        let httpUrl, id;
        // console.log(process.env.NODE_ENV)
        httpUrl = process.env.VUE_APP_httpUrl;
        id = process.env.VUE_APP_appClientId;
        // 注册
        // let tohttp = process.env.NODE_ENV === 'production' ? 'https://www.showmoney.app/wallet' : 'https://test.showmoney.io/wallet'
        // 第三方
        let SMhttp = process.env.VUE_APP_redirectUri + "/{{url}}";
        if (url === "login") {
          SMhttp = SMhttp.replace("{{url}}", "userLogin");
        } else {
          SMhttp = SMhttp.replace("{{url}}", "Matesign");
          // console.log(tohttp + '/sign?from=' + httpUrl)
        }
        // console.log(SMhttp + '?response_type=code&client_id=' + id +'&redirect_uri=' + httpUrl + '&scope=app&from=' + httpUrl)
        window.location.href =
          SMhttp +
          "?response_type=code&client_id=" +
          id +
          "&redirect_uri=" +
          httpUrl +
          "&scope=app&from=" +
          httpUrl;
      },
      // 创建MetaFile点位符
    createMetaFilePlaceholder(id) {
    return `![metafile](${id})`;
    },
    getBase64(img){
        function getBase64Image(img,width,height) {
            const canvas = document.createElement("canvas");
            canvas.width = width ? width : img.width;
            canvas.height = height ? height : img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const dataURL = canvas.toDataURL();
            return dataURL;
        }
        const image = new Image();
        image.crossOrigin ='anonymous'
        image.src = img ;
        return new Promise((resolve,reject)=>{
            image.onload =function (){
                resolve(getBase64Image(image));
            }
        });
    }
}

export default utils