<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>
<script>
// import { getAccessToken,getRefreshToken} from "@api/api.js";
// import { mapGetters } from "vuex";
export default {
  name:"App",
  data(){
    return {

      
      // async created(){
        
      //   if(!this.isApp){
      //     const code=this.$route.query.code
         
      //     if(code){
      //       this.$store.dispatch('updateUserLogining',true)
      //       console.log("有code:", code);
      //       this.$store.dispatch('updateCode', code);
      //       const res=await getAccessToken(code).catch((err)=>{
      //         this.logoutApp();
      //       })
      //       if(res){
      //       this.$store.dispatch(
      //         "updateAccessToken",
      //         res.access_token
      //       );
      //       this.$store.dispatch(
      //         "updateRefreshToken",
      //         res.refresh_token
      //       );
      //       }
      //     }
      //   }else{
      //     this.$store.dispatch("updateUserLogining", true);
      //     if(!this.refreshToken){
      //        this.$store.dispatch("updateUserLogining", false);
      //         return;
      //     }
      //     const result=await getRefreshToken(this.refreshToken).catch(err=>{
      //       this.logoutApp();
      //     })
      //     if(result){
      //       this.$store.dispatch(
      //       "updateAccessToken",
      //       result.access_token
      //       );
      //       this.$store.dispatch(
      //         "updateRefreshToken",
      //         result.refresh_token
      //       );
      //     }
      //   }
      // }
    }
  },
  methods:{
    init(){
       this.initUserInfo();
    },
   async initUserInfo() {
      let userinfo = localStorage.getItem("userInfo");
      if (userinfo) {
       await this.$store.dispatch('asyncUpdataUserInfo',userinfo)
      }
     
    },
  },
  created(){
    this.init();
  },
  computed:{
    //  ...mapGetters([
    //   "isMobile",
    //   "code",
    //   "isLogined",
    //   "refreshToken",
    //   "isApp"
    // ]),
  }
}
</script>
<style lang="scss">
html
body{
  font-size: 14px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f7fa;
}


</style>
