<template>
    <div class="headerWrap">
      <van-button square size='small' 
      type="info"
       @click="$utils.goShowMoney('login')"
        v-if="!userData && !$store.state.PhoneShow"
        :loading="$store.state.loginLoding">
            登录
       </van-button>
           <van-popover
          v-model="showPopover"
          theme="dark"
          trigger="click"
          :actions="actions"
          @select="toDetail"
           v-else
        >
          <template #reference>
          <div class="info">
          <div class="avatar"><img :src="metaIdAvatar" alt=""></div>
            <div class="right">
                <span class="name">{{ $store.state.userData.name }}</span>
                <span class="mataid">{{ $store.state.userData.metaId.slice(0,6)}}</span>
            </div>
        </div>
          </template>
        </van-popover>      
    </div>
</template>
<script>
import { getToken, refreshToken } from "@api/api";
import { mapGetters } from "vuex";
// import { initShowMoneyInjector, hexToBase64Img } from "@utils/common";
// import { mapGetters } from "vuex";
// import {goAuth} from '@api/api.js'
// import {mapState} from "vuex"
export default {
    name:'login',
    data(){
        return{
            redirectUri: process.env.VUE_APP_redirectUri,
            metaIdShow: false,
            showPopover: false,
            actions: [{ text: '我的钱包' }, { text: '退出登录' }],
            // userHead: "", // 用户头像
            // metaIdShow: false,
            // loading:false,
            // showMenu:false
        }
    },
    watch:{
        userData: {
          handler: function () {
          if (this.$store.state.userData.showId) {
          this.metaIdShow = false;
        
          this.$forceUpdate();
        }
      },
      deep: true,
    },
    },
    computed:{
      ...mapGetters([
      "isLogined",
      "hasAccessToken",
      "userData",
      ]),
      metaIdAvatar(){
        return `${process.env.VUE_APP_IMG_API}metafile/avatar/${this.$store.state.userData.showId}`
      },
      isInApp() {
      return window.appMetaIdJs;
      },
    },
    mounted(){
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.isInApp) {
          this.$store.state.PhoneShow = true;
          this.$store.state.InjectorShow = true;
          window.handleUserLoginData = this.handleUserLoginData;
          this.getUserLoginData();
        }
      }, 1000);
    });  
    },
    created(){
       this.initApp()
    },
    methods:{
    toDetail(actions,index){
      switch (index) {
        case 0:
          window.open(process.env.VUE_APP_redirectUri)
          break;
        case 1:
          this.outLogin()
          break;
        default:
          break;
      }
    }, 
    createMeta() {
      this.metaIdShow = false;
      this.$store.state.loginLoding = false;
      localStorage.removeItem("access_token");
      this.$store.commit("setAccessToken", null);
      this.$cookie.remove("access_token");
      const tohttp = process.env.VUE_APP_redirectUri + "login";
      window.open(tohttp);
    },
    goShowwallet(url) {
      let httpUrl = process.env.VUE_APP_redirectUri;
      window.open(httpUrl + url);
    },
    outLogin() {
      localStorage.removeItem("access_token");
      this.$store.commit("setAccessToken", null);
      this.$cookie.remove("access_token");
      window.location.reload();
    },
    // 刷新token
    refreshToken(token) {
      const id = process.env.VUE_APP_appClientId;
      const secret = process.env.VUE_APP_appClientSecret;
      const params = {
        grant_type: "refresh_token",
        client_id: id,
        // client_id: process.env.VUE_APP_appClientId,
        client_secret: secret,
        refresh_token: token,
      };
      return getToken(params).catch((err) => {
        return false;
      });
    },
    // 根据code获取token
    getUserToken() {
      const httpUrl = process.env.VUE_APP_httpUrl;
      const id = process.env.VUE_APP_appClientId;
      const secret = process.env.VUE_APP_appClientSecret;
      const params = {
        grant_type: "authorization_code",
        code: this.$route.query.code,
        // 'client_id': id,
        redirect_uri: httpUrl,
        scope: "app",
        client_id: id,
        client_secret: secret,
      };
      console.log("getUserToken params:", params);
      // console.log(params);
      getToken(params)
        .then((res) => {
          if (!res) return this.outLogin();
          const token = res.data.access_token;
          localStorage.setItem("access_token", JSON.stringify(res.data));
          this.$store.commit("setAccessToken", token);
          this.$cookie.set("access_token", token, res.data.expires_in);
          this.gotoUrl("Home");
          console.log(this.$route);
          this.setInjector();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.outLogin();
          }
          return false;
        });
    },
        // 实例化第三方
    setInjector() {
      console.log("实例化");
      // 'https://dev2showmoney.showpay.top'
      if (this.$store.state.Injector) {
        return;
      }
      const htmlUrl = process.env.VUE_APP_redirectUri;
      const that = this;
      const httpUrl = process.env.VUE_APP_httpUrl;
      const id = process.env.VUE_APP_appClientId;
      const secret = process.env.VUE_APP_appClientSecret;
      this.$store.state.Injector = new this.$Injector({
        baseUri: htmlUrl,
        oauthSettings: {
          clientId: id,
          clientSecret: secret,
          redirectUri: httpUrl,
        },
        onLoaded: function () {
          that.$store.state.InjectorShow = true;
          that.getUserLoginData();
        },
        onError: function (res) {
          console.log("setInjector onError:", res);
          if (res.code === 201) {
            that.$store.state.loginLoding = false;
            localStorage.removeItem("access_token");
            that.$store.commit("setAccessToken", null);
            that.$cookie.remove("access_token");
            // return that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
          } else {
            // return that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
          }
        },
      });
      this.Injector = this.$store.state.Injector;
      console.log(this.Injector);
    },
    // 获取用户信息
    getUserLoginData() {
      const accessToken = this.$cookie.get("access_token") || "";
      if (this.isInApp) {
        console.log("app getUserInfo");
        const appClientId = process.env.VUE_APP_appClientId;
        const appClientSecret = process.env.VUE_APP_appClientSecret;
        console.log(appClientId);
        window.appMetaIdJs.getUserInfo(
          appClientId,
          appClientSecret,
          "handleUserLoginData"
        );
      } else if (accessToken) {
        console.log("web getUserInfo");
        this.Injector.getUserInfo({
          accessToken,
          callback: this.handleUserLoginData,
        });
      }
    },
        handleUserLoginData(res) {
      console.log("实例化", res);
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      const accessToken = this.$cookie.get("access_token") || "";
      if (res.code === 200) {
        this.$store.state.loginLoding = false;
        this.$store.state.InjectorShow = true;
        this.$store.state.userData = res.data;
        console.log('cccc',this.$store.state.userData)
        // this.$store.state.userData.headUrl = this.$utils.hexStringToByte(
        //   this.$store.state.userData.headUrl
        // );
        // this.userData = this.$store.state.userData;
        // this.$store.commit("setUserData", this.userData);
        this.$store.state.userData.accessToken = accessToken;
        if (window.appMetaIdJs) {
          this.$store.state.appAccessToken = res.appAccessToken;
          this.$store.state.userData.accessToken = res.appAccessToken;
          this.$store.commit("setAccessToken", res.appAccessToken);
        }
        // const _label = sessionStorage.getItem("label");
        // console.log("_label", _label);
        // if (!_label) {
        //   this.tabHandle(this.follow);
        // } else {
        //   this.tabHandle(_label);
        // }
        this.InjectorLock = true;
        this.$forceUpdate();
      } else if (res.code === 202) {
        this.$store.state.loginLoding = false;
        this.metaIdShow = true;
      } else {
        this.$store.state.loginLoding = false;
        // this.dialogVisible = false;
        localStorage.removeItem("access_token");
        this.$store.commit("setAccessToken", null);
        this.$cookie.remove("access_token");
        this.outLogin();
      }
    },
      initApp() {
      // this.nav = this.hasAccessToken ? this.follow : this.hot;
      // const lang = localStorage.getItem("lang");
      // if (lang) {
      //   this.lang = lang === "zh" ? "CN" : "EN";
      // }
      console.log("create_header");
      if (this.$route.query.code) {
        this.$store.state.loginLoding = true;
        setTimeout(() => {
          if (this.$store.state.loginLoding) {
            this.$store.state.loginLoding = false;
            this.$cookie.remove("access_token");
          }
        }, 40000);
        this.getUserToken();
      } else {
        if (localStorage.getItem("access_token")) {
          if (this.$store.state.loginLoding) return;
          console.log("created header accessToken");
          this.$store.state.loginLoding = true;
          const token = JSON.parse(localStorage.getItem("access_token") || "")
            .refresh_token;
          this.refreshToken(token).then((res) => {
            if (!res) return this.outLogin();
            const token = res.data.access_token;
            if (!token) {
              return;
            }
            localStorage.setItem("access_token", JSON.stringify(res.data));
            this.$store.commit("setAccessToken", token);
            this.$cookie.set("access_token", token, res.data.expires_in);
            this.setInjector();
            this.$store.state.loginLoding = false;
            setTimeout(() => {
              if (this.$store.state.loginLoding) {
                this.$store.state.loginLoding = false;
                this.$cookie.remove("access_token");
              }
            }, 40000);
         
          });
        } else {
        }
      }
    },
    },
}
</script>
<style lang="scss" scoped>
  .headerWrap{
    padding-top: 5px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    .info {
  display: flex;
  padding: 20px 0 20px 20px;
  justify-content: center;
  align-items: center;
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin-left: 10px;
  }
}
.logout{
  margin-left: .357143rem /* 5/14 */;
}
.name {
  font-size: 18px;
  color: #333333;
}
.mataid { 
  font-size: 14px;
  color: #999999;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  img{
    width: 40px;
    height: 40px;
    object-fit: contain;
     border-radius: 40px;
    
  }
}
  }

</style>