import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import VueCookies from "vue-cookies";
import { Toast } from "vant";
import "./assets/reset.css";
import "vant/lib/index.css";
import utils from "./utils";
// import ShowMoneyInjector from "showmoney-injector";
// import MetaSdk from "@metaid/metaSdk"
// import {getShowAccount} from '@api/api'
import MetaIdJs from "metaidjs";
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(Toast);
Vue.use(VueCookies);
Vue.prototype.$utils = utils;
let timeout;
Vue.prototype.debouinitShowMoneyInjectornce = (func, wait, immediate, item) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  if (immediate) {
    const callNow = !timeout;
    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
    if (callNow) func(item);
  } else {
    timeout = setTimeout(() => {
      func(item);
    }, wait);
  }
};
Vue.prototype.gotoUrl = function (name, params) {
  const that = this;
  // console.log(this.$route)
  // console.log(params, 'this的信息')
  // if (this.$route.name === name && !this.$route.query.code) {
  //   return
  // }
  that.$router
    .push({
      name: name,
      params: {
        ...params,
      },
    })
    .catch((err) => {
      console.log(err);
    });
};
Vue.prototype.$cookie = VueCookies;
Vue.prototype.$Injector = MetaIdJs;

// Vue.mixin({
//   methods:{
//     logoutApp(){
//       this.$store.dispatch("updateUserLogining", false);
//       this.$store.dispatch("loginOut");
//     }
//   }
// })
new Vue({
  router,
  store,
  // data:()=>{
  //   return{
  //     handleUserInfo:res => {},
  //   }
  // },
  // created(){

  //   const handleUserInfo= (res) =>{
  //     if (typeof res === "string") {
  //       res = JSON.parse(res);
  //     }
  //     if(res.code===200){

  //       const userInfo = res.data;
  //       if (res.appAccessToken)
  //       this.$store.dispatch('updateAppAccessToken', res.appAccessToken)
  //       getShowAccount(userInfo.showId);
  //       const metaSdk=new MetaSdk(
  //         this.$store.getters.isApp,
  //         this.$store.getters.accessToken,
  //         this.$metaidjs
  //       )

  //       this.$store.dispatch("updateMetaSdk", metaSdk);
  //       this.$store.dispatch("updateCurrentUser", userInfo);
  //       this.$store.dispatch("updateUserLogining", false);
  //     }else{
  //       this.logoutApp()
  //     }
  //   }
  //   if (!window.handleUserInfo) {
  //     window["handleUserInfo"] = this.handleUserInfo = handleUserInfo;
  //   }
  //   if(this.$store.getters.isApp){
  //     store.dispatch("updateIsAppStatus", true)
  //     const appClientId=process.env.VUE_APP_appClientId
  //     const appClientSecret = process.env.VUE_APP_appClientSecret
  //     window.appMetaIdJs.getUserInfo(
  //       appClientId,
  //       appClientSecret,
  //       "handleUserInfo"
  //     );
  //   }else{
  //     console.log("不是showApp打开,isApp:");
  //   }
  //   store.watch(
  //     () => store.getters.accessToken,
  //     (val) => {
  //       if (val) {
  //         console.log("已登录");
  //         if (!store.getters.isApp) {
  //           const metaidjsInitParam = {
  //             baseUri: process.env.VUE_APP_redirectUri,
  //             oauthSettings:{
  //               clientId:process.env.VUE_APP_appClientId,
  //               clientSecret:process.env.VUE_APP_appClientSecret,
  //               redirectUri:process.env.VUE_APP_redirectUrl
  //             },
  //             onError: (error) => {
  //               if (error.code === 201 || error.code === 202) {
  //                 this.logoutApp();
  //               }
  //             },
  //             onLoaded: (r) => {
  //               if (metaidjs.isLoaded) {
  //                 store.dispatch(
  //                   "updateMetaIdJsStatus",
  //                   metaidjs.isLoaded
  //                 );
  //                 console.log("store.getters.accessToken:", store.getters.accessToken)
  //                 const tk = store.getters.accessToken;
  //                 metaidjs.getUserInfo({
  //                   accessToken: store.getters.accessToken || "",
  //                   callback: (res) => {
  //                     console.log("刷新了的accessToken:",store.getters.accessToken)
  //                     console.log("metaidjs.getUserInfo res:",res)
  //                     window.handleUserInfo(res);
  //                   },
  //                 });
  //               }
  //             },
  //           };
  //           var metaidjs= null;
  //           if (this.$metaidjs) {
  //             store.dispatch(
  //               appDispath.updateMetaIdJsStatus,
  //               this.$metaidjs.isLoaded
  //             );
  //             this.$metaidjs.getUserInfo({
  //               accessToken: store.getters.accessToken || "",
  //               callback: (res) => {
  //                 window.handleUserInfo(res);
  //               },
  //             });
  //           } else {
  //             console.log("metaidjsInitParam:", metaidjsInitParam);
  //             metaidjs = new MetaIdJs(metaidjsInitParam);
  //             Vue.prototype.$metaidjs = this.$metaidjs = metaidjs;
  //           }

  //         }
  //       } else {
  //       }
  //     }
  //   );
  // },
  render: (h) => h(App),
}).$mount("#app");
