<template>
  <div class="home">
    <Login />
    <div class="formWrap">
      <van-form validate-first>
        <!-- 通过 pattern 进行正则校验 -->
        <van-field
          :required="true"
          size="large"
          v-model="webotName"
          label="请输入微博账号"
          placeholder="请输入微博账号"
        />
        <!-- 通过 validator 进行函数校验 -->
        <van-field
          :required="true"
          v-model="fee"
          type="number"
          size="large"
          placeholder="请填写费率"
          label="可自定义费率(默认0.5)"
          :center="true"
        />
        <van-field
          :required="false"
          v-model="userEmail"
          type="string"
          size="large"
          placeholder="请输入接收邮件的邮箱"
          label="用于钱包余额不足时提示"
          :center="true"
        />
        <van-field
          :required="true"
          v-model="minute"
          type="number"
          size="large"
          placeholder="可自定义监控频率"
          label="请填写监控频率(单位*分钟)"
          :center="true"
        />
        <van-field
          v-model="cancelTime"
          type="number"
          size="large"
          placeholder="可自定义监控频率"
          label="如需执行定时取消请输入时间(单位/分钟)"
          :center="true"
        />
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            @click.stop="startClawer"
            :disabled="disabled"
            >开始执行</van-button
          >
        </div>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            @click.stop="cancelSpiderSetTimeOut"
            :disabled="cancelTimeDisabled"
            >开启定时取消执行</van-button
          >
        </div>
        <div style="margin: 16px">
          <van-button round block type="warning" @click.stop="cancelSpider"
            >取消执行</van-button
          >
        </div>
        <div style="margin: 16px">
          <van-button round block type="warning" @click.stop="send"
            >发送执行</van-button
          >
        </div>
        <!-- <div >
    <img :src="base64Img" alt="" style="width:100px;heigth:100px">
  </div> -->
      </van-form>
    </div>
  </div>
</template>

<script>
import { queryFindMetaData, getList, cancelService, sendEmail } from "@api/api";
import Login from "@c/login.vue";
// import {transdate,placeHolder} from "@utils/data"
export default {
  name: "Home",
  components: {
    Login,
  },
  data() {
    return {
      blob: {},
      webotName: "",
      fee: 0.5,
      minute: 30,
      total: 1,
      base64Img: "",
      contentEncrypt: 0,
      blogComtent: "",
      imgDataList: [],
      imgData: {},
      imgUrl: "",
      spiderData: {},
      base64ImgArr: "",
      imgDataArr: "",
      disabled: false,
      cancelTime: 0,
      cancelTimeDisabled: false,
      isCancel: false,
      timer: null,
      userEmail: "",
    };
  },
  watch: {
    // isCancel:{
    //   handler(val){
    //     if(val){
    //       console.log(val)
    //       this.startClawer()
    //     }
    //   }
    // }
  },

  methods: {
    send() {
      sendEmail({
        email: this.userEmail,
        address: this.$store.state.userData.address,
      }).then((res) => {
        console.log(res);
        this.$toast.success("发送邮件成功");
      });
    },
    startClawer() {
      this.isCancel = false;
      console.log("执行了");
      if (!this.webotName) {
        this.$toast.fail("微博账号不能为空");
        return;
      }
      this.$toast.loading({
        message: "执行中...",
        forbidClick: true,
      });
      this.disabled = true;

      // let i=1
      //,requestCount:i,isCancel:false
      getList({ webotName: this.webotName }).then((res) => {
        this.blogComtent = res.data.content;
        this.base64ImgArr = res.data.imgList;
        this.imgDataArr = res.data.ImgFile;
        if (res.data.imgList) {
          this.urlToFile(this.base64ImgArr, this.imgDataArr).then((resp) => {
            this.issueSimpleMicroblog();
          });
        } else {
          this.issueSimpleMicroblog();
        }
      });
      this.timer = setInterval(() => {
        if (!this.isCancel) {
          console.log("进来定时器了");
          this.blogComtent = "";
          this.base64ImgArr = [];
          this.imgDataArr = [];
          getList({ webotName: this.webotName }).then((res) => {
            this.blogComtent = res.data.content;
            this.base64ImgArr = res.data.imgList;
            this.imgDataArr = res.data.ImgFile;
            if (res.data.imgList) {
              this.urlToFile(this.base64ImgArr, this.imgDataArr).then(() => {
                this.issueSimpleMicroblog();
              });
            } else {
              this.issueSimpleMicroblog();
            }
          });
        } else {
          this.blogComtent = "";
          this.base64ImgArr = [];
          this.imgDataArr = [];
          clearInterval(this.timer);
        }
      }, Number(this.minute) * 60 * 1000);
    },
    cancelSpider() {
      this.$toast.success("已取消监听");
      this.disabled = false;
      this.isCancel = true;
      clearInterval(this.timer);
    },
    cancelSpiderSetTimeOut() {
      console.log(this.cancelTime, this.minute);
      if (
        this.cancelTime <= 0 ||
        Number(this.cancelTime) <= Number(this.minute)
      ) {
        this.$toast.fail("定时设定时间不能小于0或小于监控时间");
        return;
      }
      this.cancelTimeDisabled = true;

      this.$toast.success("已开启定时取消监听");
      setInterval(() => {
        this.$toast.success("已取消监听");
        this.isCancel = true;
        this.cancelTimeDisabled = false;
        this.disabled = false;
        clearInterval();
      }, Number(this.cancelTime) * 60 * 1000);
    },
    base64Toblob(dataurl) {
      return new Promise((resolve, reject) => {
        let arr = dataurl.split(",");
        //注意base64的最后面中括号和引号是不转译的
        let _arr = arr[1].substring(0, arr[1].length - 2);
        let mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(_arr),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        resolve(new Blob([u8arr], { type: mime }));
      });
    },
    // 图片转2进制流
    async handleFileChange(e, filename, filetype) {
      console.log("88888", e);
      const file = e;
      const fileType = file.type;
      const reader = new FileReader();
      let fileBinary;
      reader.onload = () => {
        const arrayBuffer = reader.result;
        let buffer;
        let hex;
        if (arrayBuffer) {
          buffer = Buffer.from(arrayBuffer);
          hex = buffer.toString("hex");
          fileBinary = buffer;
        }
        const fileData = this.base64Img;
        const imgData = {};
        imgData.base64Data = fileData;
        imgData.BufferData = fileBinary;
        imgData.hexData = hex;
        imgData.name = filename;
        imgData.type = filetype;
        /*
          fileBinary二进制流
          fileData 图片base64编码
          fileType 文件名
        */
        this.imgDataList.push(imgData);
        console.log("99999", this.imgDataList);
      };
      reader.readAsArrayBuffer(file);
    },

    urlToFile(base64ImgArr, imgDataArr) {
      return new Promise((resolve, reject) => {
        if (base64ImgArr instanceof Array) {
          base64ImgArr.forEach((item, index) => {
            let fileName = imgDataArr[index].split("/")[4];
            let fileType = "image/jpeg";
            this.base64Toblob(`data:image/jpg;base64,${item}`).then((res) => {
              this.handleFileChange(res, fileName, fileType);
            });
          });
        } else {
          let fileName = imgDataArr.split("/")[4];
          let fileType = "image/jpeg";
          this.base64Toblob(`data:image/jpg;base64,${base64ImgArr}`).then(
            (res) => {
              console.log(res);
              this.handleFileChange(res, fileName, fileType);
            }
          );
        }
        resolve(true);
      });
    },
    issueSimpleMicroblog() {
      if (this.$store.state.loginLoding) {
        this.$toast.fail("登录中");
        return;
      }
      if (!this.$store.state.InjectorShow) {
        return;
      }
      if (!this.blogComtent) {
        this.$toast.fail("微博名称不能为空");
        return;
      }
      if (this.imgDataList.length > 0) {
        let parms = {
          find: {
            parentNodeName: "SimpleMicroblog",
            metaId: process.env.VUE_APP_IDtags,
            rootTxId: this.$store.state.userData.showId,
            // parentTxTd: { $ne: this.$store.state.userData.protocolTxId },
            // isValid: true,
            // isNew: true
          },
          // sort: {
          //   timestamp: -1,
          // },
          // skip: this.total,
          // limit: 20,
        };
        parms = btoa(JSON.stringify(parms));
        queryFindMetaData(parms).then((res) => {
          let payto = [];
          payto = [
            {
              amount: parseInt(process.env.VUE_APP_PayUser),
              address: "16zGks6gDdU6YaYLvwJ82mumt3zUjWnVCW",
            },
          ];
          // if (newUser > 0) {

          // }
          console.log("222", payto);
          this.accessToken = this.$cookie.get("access_token") || "";
          const that = this;
          // const attachments = [];
          const attachments = [];
          // const metafile = that.imgData.imgTX ? 'metafile://' + that.imgData.imgTX : ''
          this.imgDataList.map((ele) => {
            attachments.push({
              fileName: ele.name,
              fileType: ele.type,
              data: ele.hexData,
              // encrypt: this.contentEncrypt
            });
          });
          console.log("hahaha", attachments);
          const protocolData = {
            createTime: parseInt(new Date().getTime()),
            content: that.blogComtent,
            contentType: "text/plain",
            quoteTx: "",
            attachments: attachments.map((item, index) => {
              return this.$utils.createMetaFilePlaceholder(index);
            }),
            mention: [],
          };
          console.log("333", protocolData.attachments);

          window.attachments = attachments; //for app test
          this.imgData.imgTX = attachments;
          console.log("444", this.imgData.imgTX);
          const blogNode = {
            nodeName: "SimpleMicroblog",
            metaIdTag: process.env.VUE_APP_IDtags,
            brfcId: "9e73d8935669",
            accessToken: that.accessToken,
            encrypt: that.contentEncrypt,
            payCurrency: "BSV",
            payTo: payto,
            path: "/Protocols/SimpleMicroblog",
            dataType: "application/json",
            attachments: attachments,
            attachmentsUseMetaSv: +process.env.VUE_APP_attachmentsUseMetaSv,
            data: JSON.stringify(protocolData),
            
          };
          blogNode.needConfirm = false;
          console.log("blogNode", blogNode);
          if (this.$store.state.PhoneShow) {
            window.handleSimpleMicroblog = this.handleSimpleMicroblog();
            // delete blogNode.attachments
            window.appMetaIdJs.sendMetaDataTx(
              this.$store.state.appAccessToken,
              JSON.stringify(blogNode),
              "handleSimpleMicroblog"
            );
          } else {
            blogNode.callback = (res) => {
              console.log("开始付费", res);
              this.handleSimpleMicroblog()(res);
            };
            this.$store.state.Injector.addProtocolNode(blogNode);
          }
        });
      } else {
        let parms = {
          find: {
            parentNodeName: "SimpleMicroblog",
            metaId: process.env.VUE_APP_IDtags,
            rootTxId: this.$store.state.userData.showId,
            // parentTxTd: { $ne: this.$store.state.userData.protocolTxId },
            // isValid: true,
            // isNew: true
          },
          // sort: {
          //   timestamp: -1,
          // },
          // skip: this.total,
          // limit: 20,
        };
        console.log("111", parms);
        parms = btoa(JSON.stringify(parms));
        queryFindMetaData(parms).then((res) => {
          let payto = [];
          payto = [
            {
              amount: parseInt(process.env.VUE_APP_PayUser),
              address: "16zGks6gDdU6YaYLvwJ82mumt3zUjWnVCW",
            },
          ];
          // if (newUser > 0) {

          // }
          console.log("222", payto);
          this.accessToken = this.$cookie.get("access_token") || "";
          const that = this;
          // const attachments = [];
          const attachments = [];
          // const metafile = that.imgData.imgTX ? 'metafile://' + that.imgData.imgTX : ''
          this.imgDataList.map((ele) => {
            attachments.push({
              fileName: ele.name,
              fileType: ele.type,
              data: ele.hexData,
              encrypt: this.contentEncrypt,
            });
          });
          console.log("hahaha", attachments);
          const protocolData = {
            createTime: parseInt(new Date().getTime()),
            content: that.blogComtent,
            contentType: "text/plain",
            quoteTx: "",
            attachments: attachments.map((item, index) => {
              return this.$utils.createMetaFilePlaceholder(index);
            }),
            mention: [],
          };
          window.attachments = attachments; //for app test
          this.imgData.imgTX = attachments;
          const blogNode = {
            nodeName: "SimpleMicroblog",
            metaIdTag: process.env.VUE_APP_IDtags,
            brfcId: "9e73d8935669",
            accessToken: that.accessToken,
            encrypt: that.contentEncrypt,
            payCurrency: "BSV",
            payTo: payto,
            path: "/Protocols/SimpleMicroblog",
            dataType: "application/json",
            attachments: attachments,
            data: JSON.stringify(protocolData),
          };
          blogNode.needConfirm = false;
          console.log("blogNode", blogNode);
          if (this.$store.state.PhoneShow) {
            window.handleSimpleMicroblog = this.handleSimpleMicroblog();
            // delete blogNode.attachments
            window.appMetaIdJs.sendMetaDataTx(
              this.$store.state.appAccessToken,
              JSON.stringify(blogNode),
              "handleSimpleMicroblog"
            );
          } else {
            blogNode.callback = (res) => {
              console.log("开始付费", res);
              this.handleSimpleMicroblog()(res);
            };
            this.$store.state.Injector.addProtocolNode(blogNode);
          }
        });
      }
    },
    handleSimpleMicroblog() {
      const that = this;
      return function (res) {
        try {
          if (typeof res === "string") {
            res = JSON.parse(res);
          }
          if (res.code === 200) {
            that.imgDataList = [];
            that.imgData = {};
            that.blogComtent = "";
            that.$toast.success("发布成功");
          }
        } catch (error) {
          console.log(res.data.message);
        }
      };
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.home {
  max-width: 600px;
  height: 100vh;
  background-color: #fff;
  margin: 0 auto;

  .formWrap {
    display: flex;
    flex-direction: column;
  }
}
</style>
