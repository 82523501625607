import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// Vue.config.devtools=true
export default new Vuex.Store({
  state: {
    userData: "",
    appAccessToken: "",
    Injector: "",
    InjectorShow: false,
    loginLoding: false,
    PhoneShow: false,
    accessToken: null,
  },
  getters: {
    userData(state) {
      return state.userData;
    },
    isLogined(state) {
      return !!state.accessToken && !!state.userData;
    },
    hasAccessToken(state) {
      return !!state.accessToken;
    },
    accessToken(state) {
      if (state.accessToken) {
        return state.accessToken;
      } else {
        return localStorage.getItem("access_token");
      }
    },

  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
  },
 
  
});



// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)
// // import { getLocal,removeLocal,setLocal } from "@utils/localStorage.js";
// export default new Vuex.Store({
//   state: {

//     userInfo: {}, // 登录账户个人信息
//     loginLoding: false,
//     Injector: "",
//     appAccessToken: "",
//     isAppPlateform: false
//     // code: "",
//     // accessToken: null,
//     // appAccessToken: "",
//     // refreshToken: '',
//     // isApp: false,
//     // isMobile: false,
//     // appMetaIdJs: window.appMetaIdJs,
//     // isLoadMetaIdJs: false,
//     // metaSdk: undefined,
//     // userLogining: false,
//     // currentUser: undefined,
//   },
//   getters:{
//     // isApp(state){
//     //   return state.appMetaIdJs!=undefined 
//     // },
//     // currentUser(){
//     //   return state.currentUser
//     // },
//     // isToken(state){
//     //   const accessToken=state.accessToken || getLocal("accessToken")
//     //   const appAccessToken=state.appAccessToken || getLocal("appAccessToken")
//     //   return accessToken || appAccessToken;
//     // },
//     // accessToken(state) {
//     //   if (state.accessToken) {
//     //     return state.accessToken;
//     //   } else if (getLocal("accessToken")) {
//     //     return getLocal("accessToken");
//     //   } else {
//     //     return "";
//     //   }
//     // },
//     // appAccessToken(state) {
//     //   if (state.appAccessToken) {
//     //     return state.appAccessToken;
//     //   } else if (getLocal("appAccessToken")) {
//     //     return getLocal("appAccessToken");
//     //   } else {
//     //     return "";
//     //   }
//     // },
//     // refreshToken(state){
//     //   if (state.refreshToken) {
//     //     return state.refreshToken;
//     //   } else if (getLocal("refreshToken")) {
//     //     return getLocal("refreshToken");
//     //   } else {
//     //     return "";
//     //   }
//     // },
//     // metaSdk(state){
//     //   return state.metaSdk;
//     // },
//     // currentUser(state){
//     //   return state.currentUser;
//     // },
//     // userLogining(state){
//     //   return state.userLogining;
//     // },
//     // isMobile(state) {
//     //   return state.isMobile;
//     // },
//     // code(state) {
//     //   return state.code;
//     // },
//     // isLogined(state) {
//     //   return !!state.accessToken || !!state.appAccessToken;;
//     // },
//   },
//   mutations: {
//     // updateIsMobile(state, isMobile) {
//     //   state.isMobile = isMobile;
//     // },
//     // updateAccessToken(state, accessToken) {
//     //   state.accessToken = accessToken;
//     // },
//     // updateRefreshToken(state,refreshToken){
//     //   state.refreshToken = refreshToken;
//     // },
//     // updateCode(state,code){
//     //   state.code = code;
//     // },
//     // loginOut(state){
//     //   state.accessToken = undefined;
//     //   state.appAccessToken = undefined;
//     //   state.currentUser = undefined;
//     //   removeLocal("accessToken");
//     //   removeLocal("refreshToken");
//     //   removeLocal("appAccessToken");
//     // },
//     // updateAppAccessToken(state,appAccessToken){
//     //   setLocal("appAccessToken", appAccessToken);
//     //   state.appAccessToken = appAccessToken;
//     // },
//     // updateMetaIdJsStatus(state,isLoadMetaIdJs){
//     //   state.isLoadMetaIdJs = isLoadMetaIdJs;
//     // },
//     // updateMetaSdk(state,metaSdk){
//     //   state.metaSdk = metaSdk;
//     // },
//     // updateUserLogining(state,userLogining){
//     //   state.userLogining = userLogining;
//     // }
//     updataUserInfo(state,payload){
//       state.userInfo=payload
//     }
//   },
//   actions: {
//     asyncUpdataUserInfo({commit},payload){
//       commit("updataUserInfo", payload);
//     }
//     // updateIsMobile({commit},payload){
//     //   commit("updateIsMobile", payload);
//     // },
//     // updateAccessToken({ commit }, payload) {
//     //   commit("updateAccessToken", payload);
//     // },
//     // updateRefreshToken({ commit }, payload) {
//     //   commit("updateRefreshToken", payload);
//     // },
//     // updateCode({ commit }, payload) {
//     //   commit("updateCode", payload);
//     // },
//     // loginOut({ commit }) {
//     //   console.log("退出登录");
//     //   commit("loginOut");
//     // },
//     // updateAppAccessToken({ commit }, payload) {
//     //   commit("updateAppAccessToken", payload);
//     // },
//     // updateMetaIdJsStatus({ commit } , payload) {
//     //   commit("updateMetaIdJsStatus", payload);
//     // },
//     // updateCurrentUser({ commit }, payload) {
//     //   commit("updateCurrentUser", payload);
//     // },
//     // updateMetaSdk({ commit }, payload) {
//     //   commit("updateMetaSdk", payload);
//     // },
//     // updateUserLogining({ commit }, payload) {
//     //   commit("updateUserLogining", payload);
//     // },
//   },
//   modules: {
//   }
// })
