import axios from "axios";
import qs from "qs";
var axiosApi = axios.create();
const EXPIRE_TIME = 30000;
let pedding = {};

const createReqStr = (config) => {
  let reqStr;
  if (config.method === "get") {
    reqStr = config.url + config.method;
  }
  if (config.method === "post") {
    reqStr =
      config.url +
      config.method +
      (typeof config.data == "string"
        ? config.data
        : JSON.stringify(config.data));
  }
  return reqStr;
};
const createCacheResponseData = (promise) => {
  return {
    promise: promise,
    time: Date.now(),
  };
};
axiosApi.interceptors.request.use(
  (reqConfig) => {
    return reqConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const handleRequestPromise = (options) => {
  return new Promise(async (resolve, reject) => {
    try {
      let key = createReqStr({
        url: options.url,
        method: options.method,
        data: options.data,
      });
      let requests = pedding[key];
      let axiosApiConfig = {
        method: options.method,
        url: options.url,
      };
      if (options.method == "post") {
        axiosApiConfig["data"] = options.data;
      }
      if (options.transformRequest) {
        axiosApiConfig["transformRequest"] = options.transformRequest;
      }
      if (options.headers) {
        axiosApiConfig["headers"] = options.headers;
      }
      if (requests) {
        pedding[key].push(axiosApiConfig);
        let reponsenData = pedding[key][0];
        if (reponsenData.time + EXPIRE_TIME > Date.now()) {
          //可用 response
          reponsenData.promise
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reponsenData = createCacheResponseData(axiosApi(axiosApiConfig));
          pedding[key] = [reponsenData];
          reponsenData.promise
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
          //不可用 response
        }
      } else {
        let reponsenData = createCacheResponseData(axiosApi(axiosApiConfig));
        pedding[key] = [reponsenData];
        reponsenData.promise
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const queryFindMetaData = (params) => {
  return axiosApi({
    url: "/v2showMANDB/api/v1/query/queryFindMetaData/" + params,
    method: "get",
  });
};

export const getList = (params) => {
  return handleRequestPromise({
    url: `https://webcrawler.showpay.top/webot/getList`,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "post",
    data: JSON.stringify(params),
  });
};

export const sendEmail = (params) => {
  return handleRequestPromise({
    // url:`/email`,
    // url:'http://127.0.0.1:8090/getList',
    url: `https://webcrawler.showpay.top/webot/email`,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "post",
    data: JSON.stringify(params),
  });
};

// export const cancelService=(params)=>{
//   return axiosApi({
//     // url:`/cancelWatcher`,
//     // url:'113.73.14.141:8305/cancelWatcher',
//     url:'http://127.0.0.1:8090/cancelWatcher',
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8"
//     },
//     method:'post',
//     data:JSON.stringify(params),
//   })
// }

export const getMetaImageData = (params) => {
  return handleRequestPromise({
    method: "get",

    url:
      "https://api.showmoney.app/showMANDB/api/v1/metanet/getMetaImageData/" +
      params,
    // url: '/api/core/api/v1/login/check',
    // data: params
  });
};

export const getOwnShowAccount = (params) => {
  return handleRequestPromise({
    method: "get",
    url: "/api/showMANDB/api/v1/query/getMetaIDInfo/" + params,
  });
};

// 获取第三方登录token
export const getToken = (params) => {
  return axiosApi({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    // url: 'http://192.168.168.83:9001/oauth/token',
    url: "/showmoney/oauth2/oauth/token",
    data: params,
    transformRequest: [
      function (data) {
        return qs.stringify(data);
      },
    ],
  });
};

// import {getToken,getData} from ".";

// export const getAccessToken = (code) => {
//     return new Promise(async (resolve, reject) => {
//       const res = await getToken({
//         grant_type: "authorization_code",
//         code: code,
//         redirect_uri: process.env.VUE_APP_redirectUri,
//         scope: "app",
//         client_id: process.env.VUE_APP_appClientId,
//         client_secret: process.env.VUE_APP_appClientSecret,
//       }).catch((err) => {
//         //授权出错处理
//         reject(err);
//       });
//       if (res) {
//         resolve(res);
//       }
//     });
//   };

//   export const getRefreshToken = (refreshToke) => {
//     return new Promise(async (resolve, reject) => {
//       const res = await getToken({
//         grant_type: "refresh_token",
//         refresh_token: refreshToke,
//         client_id: process.env.VUE_APP_appClientId,
//         client_secret: process.env.VUE_APP_appClientSecret,
//       }).catch((err) => {
//         reject(err);
//       });
//       resolve(res);
//     });
//   };

//   export const goAuth = () => {
//     const authUrl = `${process.env.VUE_APP_redirectUri}/userLogin?response_type=code&scope=app&client_id=${process.env.VUE_APP_appClientId}&redirect_uri=${process.env.VUE_APP_httpUrl}`;
//     window.location.replace(authUrl);
//   };

//   export const getShowAccount=(txid)=>{
//     return new Promise(async(resolve,reject)=>{
//       const r=await getData("/api/showMANDB/api/v1/query/getMetaIDInfo/" + txid).catch(err=>{

//       })
//       if(r.code==200){
//         return resolve(r.result);
//       }else{
//         return reject(r.error);
//       }
//     })
//   }
